<template>
    <section class="ticket-tabs">
        <header>
            <div class="back" @click="$emit('back')">
                <figure>
                    <img src="/assets/images/left-arrow.svg" />
                </figure>
                <span>go back</span>
            </div>
        </header>
        <div class="body">
            <template v-for="(tab, i) in tabs" :key="i">
                <span :class="{active: activeTab === i}" @click="activeTab = i">{{tab}}</span>
            </template>
        </div>
    </section>
    <section class="tab-content">
        <Header :data="ticket" :type="type" @editTicket="edit = !edit"/>
        <Messages v-if="activeTab === 0" :details="ticket" :type="type" :edit="edit" @closeEdit="edit = false"/>
        <Assign
          v-else-if="activeTab === 1"
          :details="ticket"
          :canEdit="canCreate.includes(getUserProfile.role.id) && !type.length"
          @updateData="getTicketDetails"
        />
        <ProductDetails v-else-if="activeTab === 2" :details="ticket" />
        <Details v-else-if="activeTab === 3" :details="ticket" />
        <Documents v-else-if="activeTab === 4" :details="ticket" />
        <Guides v-else-if="activeTab === 5" :details="ticket" />
    </section>
</template>

<script>
import tabs from './tabs.json'
import { mapGetters } from "vuex";
import axiosInstance from "@/services/AxiosTokenInstance";
import Header from './Header.vue'
import Messages from '../../../Ticket/Messages.vue'
import Assign from "../../../Ticket/Assign.vue";
import Details from "../../../Ticket/Details.vue";
import Documents from "../../../Ticket/Documents.vue";
import Guides from "../../../Ticket/Guides.vue";
import ProductDetails from "../../../Ticket/ProductDetails.vue";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";

export default {
    props: {
        selected: {
            type: Object,
            required: false,
            default: () => {}
        },
    },

    emits: ['back'],

    components:{
        Header,
        Messages,
        Assign,
        Details,
        ProductDetails,
        Documents,
        Guides
    },

    data() {
        return {
            tabs,
            activeTab: 0,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            loaded: false,
            ticket: {},
            canCreate: [4, 5, 6, 7, 8],
            edit: false
        }
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        type() {
            return this.selected.type ? [this.selected.type, this.selected.room] : []
        }
    },

    methods: {
        async getTicketDetails() {
            const response = await axiosInstance.get(`ticket/${this.selected.id}?org_id=${this.org.id}`).finally(() => this.loaded = true)
            this.ticket = response.data.data
        }
    },

    created() {
        this.getTicketDetails()
    }
    
}
</script>

<style lang="scss">
    .ticket-tabs {
        width: 20rem;
        height: 70vh;
        background: white;
        border-right: solid 0.2rem #f2f5f8;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @media only screen and (max-width: 768px) {
            height: unset;
            width: 100%;
            border-right: unset;
        }

        header {
            display: flex;
            align-items: center;
            padding-inline: 1rem;
            border-bottom: solid 0.2rem #f2f5f8;
            height: 4rem;

            .back {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                user-select: none;
                cursor: pointer;
                opacity: 0.7;
                transition: 0.2s;

                &:hover {
                    opacity: 1;
                }

                figure {
                    width: 15px;
                    height: 15px;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .body {
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 768px) {
                flex-direction: row;
                overflow: auto;
            }

            span {
                width: 100%;
                padding: 2rem;
                transition: 0.2s;
                user-select: none;
                cursor: pointer;
                text-transform: capitalize;
                font-weight: 500;

                @media only screen and (max-width: 768px) {
                    padding: 10px 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    background: #f2f5f8a9;
                }

                &.active {
                    background: #f2f5f8;
                    color: var(--primary-color);
                }
            }
        }
    }
    .tab-content {
        width: calc(100% - 20rem);
        height: 70vh;
        background: white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
</style>