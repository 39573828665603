<template>
    <div class="item">
        <label>
			<span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
		</label>
        <div class="select">
            <template v-for="(el, i) in item.options" :key="i">
                <label class="container">
                    {{el.answer}}
                    <input type="checkbox" :value="{id: el.id, q_id: el.category_question_id}" v-model="checked">
                    <span class="checkmark"></span>
                </label>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            checked: []
        }
    },

    watch: {
        checked(val) {
            this.$emit('data', ['answers', val])
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
		font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
	}

    .select {
        margin-top: .25rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .container {
            position: relative;
            padding-left: 2rem;
            cursor: pointer;
            user-select: none;
            color: black;display: block;
            font-weight: 400;
            margin: 0;

            &:hover {
                input ~ .checkmark {
                    background-color: $grey;
                }
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked{
                    ~ .checkmark {
                        background-color: var(--primary-color);

                        &:after {
                            display: block;
                        }
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #eee;
                display: flex;
                align-items: center;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
</style>