<template>
    <span class="priority" :class="[data.ticket_priority.priority_details.name.toLowerCase()]"></span>
    <div class="ticket-info">
        <div class="info">
            <span v-if="unreadMessages" class="unreadMessage">{{unreadMessages}}</span>
            <div class="title">
                <span class="text">{{data.title}}</span>
                <span class="id">#{{data.id}}</span>
            </div>
            <div class="data" v-if="data.client">
                <span class="label">Client:</span>
                <span>{{data.client?.name}}</span>
            </div>
            <div class="data">
                <span class="label">Product:</span>
                <span>{{data.product.name}}</span>
            </div>
        </div>
        <div class="assigned" v-if="data.assigned_users">
            <template v-if="data.assigned_users.length">
                <span class="label">Assigned to:</span>
                <div class="items">
                    <template v-for="(user, i) in data.assigned_users" :key="i">
                        <figure v-if="i < 4" :title="user.name">
                            <img v-if="user.avatar" :src="imgURL + user.avatar" alt="user">
                            <span v-else class="user">{{user.name.charAt(0)}}</span>
                        </figure>
                        <figure v-else-if="i === data.assigned_users.length - 1 && data.assigned_users.length > 4" :title="user.name">
                            <span class="extra"> +{{data.assigned_users.length - 4}}</span>
                        </figure>
                    </template>
                </div>
            </template>
            <template v-else>
                <span>This Ticket is yet to be assigned.</span>
            </template>
        </div>
    </div>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance'

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        unreads: {
            type: Array,
            required: false,
            default: () => []
        },
    },

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            unreadMessages: 0,
            roomId: undefined
        }
    },

    watch: {
        unreads: {
            immediate: true,
            handler(val) {
                if(val.length) this.unreadMessages = val[0].chatCount
            }
        }
    },

    mounted() {
        this.emitter.on("new-message", (data) => {
            if(data.room?.ticketId === this.data.id) this.getUnreads()
        })
    },

    methods: {
        async getUnreads() {
            const response = await nodeInstance.get(`/chats/ticketUnreadCounts?userId=${this.user.id}&ticketIds=${this.data.id}`)
            this.unreadMessages = response.data.length ? response.data[0].chatCount : 0
        }
    }
}
</script>

<style lang="scss" scoped>
    .priority {
        width: 0.4rem;
        height: 100%;

        &.high {
          background: #ed0423;
        }
        &.medium {
          background: #fb9a4b;
        }
        &.low {
          background: #cad6e2;
        }
    }

    .ticket-info {
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        width: 100%;

        .unreadMessage {
            position: absolute;
            right: calc(1rem + 5px);
            top: calc(1.5rem + 5px);
            width: 25px;
            height: 25px;
            background: var(--primary-color);
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            width: 100%;

            .title {
                font-weight: 600;
                font-size: 1.1rem;
                display: flex;
                margin-bottom: 0.5rem;
                gap: 0.5rem;
                width: 100%;

                .text {
                    max-width: 80%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .id {
                    color: $grey;
                }
            }

            .data {
                display: flex;
                gap: 0.2rem;
                font-size: 0.8rem;

                .label {
                    font-weight: 500;
                }
            }
        }

        .assigned {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            .label {
                font-weight: 500;
                font-size: 0.8rem;
            }

            .items {
                display: flex;

                figure {
                    width: 30px;
                    height: 30px;
                    overflow: hidden;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                    user-drag: none;

                    &:not(:first-of-type) {
                        margin-left: -10px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        background: var(--primary-color);
                        font-weight: 500;
                        border-radius: 50%;
                        font-size: 0.8rem;

                        &.extra {
                            background: white;
                            border: solid 1px var(--primary-color);
                            color: var(--primary-color);
                        }
                    }
                }
            }

            
        }
    }
</style>