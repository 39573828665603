<template>
    <template v-if="finalItems.length">
        <template v-for="(item, index) in finalItems" :key="index">
            <Text v-if="item.template === 'text'" :item="item" @data="handleSelects" />
            <Textarea v-if="item.template === 'textarea'" :item="item" @data="handleSelects" />
            <Checkbox v-if="item.template === 'checkbox'" :item="item" @data="handleSelects" />
            <multiple-select v-else-if="item.template === 'multiple-select'" :item="item" @data="handleSelects" />
            <select-related v-else-if="item.template === 'select-related'" :item="item" @data="handleSelects"/>
        </template>
    </template>
    <template v-else>
        <span>There are no specific questions for this ticket. You can just create it below.</span>
    </template>
</template>
<script>
import Text from '../Templates/Normal.vue'
import Textarea from '../Templates/Textarea.vue'
import MultipleSelect from '../Templates/MultipleSelect.vue'
import SelectRelated from '../Templates/SelectRelated.vue'
import Checkbox from '../Templates/Checkbox.vue'
import api from "@/mixins/optionsApi";
export default {
    props: {
        data: {
            type: Array || String
        }
    },

    emits: ['step'],

    mixins: [api],

    data() {
        return {
            finalData: {},
            finalItems: this.data
        }
    },
    components: {
        Text,
        MultipleSelect,
        SelectRelated,
        Textarea,
        Checkbox
    },
    watch: {
        finalData: {
            deep: true,
            handler(val) {
                this.$emit('step', val)
            }
        },
        data() {
            this.fillApis()
        },


    },
    methods: {
        handleSelects(data) {
            if (data[0] === 'answers') {
                if (!this.finalData[data[0]]) this.finalData[data[0]] = []
                this.finalData[data[0]].push(...data[1])
            }
            else this.finalData[data[0]] = data[1]
        },
        async fillApis() {
            const toFetch = this.data.filter(el => el.options)
            const questions = this.data.filter(el => el.api)

            if(toFetch.length) {
                toFetch.forEach(el => {
                    const i = this.finalItems.map(e => e.name).indexOf(el.name)
                    this.finalItems[i].options = this.api(el.options)
                })
            }
            else if (questions.length) {
                const api = questions[0].api
                const r = await this.api(api, [this.finalData.product?.category.id, 1])
                this.finalItems = r
            }
            else this.finalItems = this.data
        }
    },

    mounted() {
        this.fillApis()
    }
}
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: column;
}
</style>